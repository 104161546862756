<template>
  <Registration :pageType="pageType"></Registration>
</template>

<script>
import Registration from "@/views/modules/collection/collect/registration.vue";

export default {
    name: "toExamine",
    components: {Registration},
    data(){
        return{
            pageType:'toExamine',
        }
    }
}
</script>

<style scoped>

</style>